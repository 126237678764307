import { type ParseRoute, getRouteApi } from "@tanstack/react-router";
import type {
	PaginationState,
	RowSelectionState,
	Updater,
} from "@tanstack/react-table";
import type { routeTree } from "~/routeTree.gen";

const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZE = 50;

interface TableProps {
	id: ParseRoute<typeof routeTree>["id"];
	rowCount: number;
}

export function useTableProps({ id, rowCount }: TableProps) {
	const routeApi = getRouteApi(id);
	const searchParams = routeApi.useSearch();
	const navigate = routeApi.useNavigate();

	const pagination = {
		pageIndex:
			"page" in searchParams ? searchParams.page - 1 : DEFAULT_PAGE_INDEX,
		pageSize:
			"per_page" in searchParams ? searchParams.per_page : DEFAULT_PAGE_SIZE,
	};

	function onPaginationChange(updaterOrValue: Updater<PaginationState>) {
		const params =
			typeof updaterOrValue === "function"
				? updaterOrValue(pagination)
				: updaterOrValue;

		navigate({
			search: (prev) => ({
				...prev,
				selected: undefined,
				page: params.pageIndex + 1,
				per_page: params.pageSize,
			}),
		});
	}

	const paginationProps = {
		manualPagination: true,
		rowCount: rowCount,
		state: { pagination: pagination },
		onPaginationChange: onPaginationChange,
	};

	const SEPARATOR = ",";
	const selectedParam =
		"selected" in searchParams ? searchParams.selected : undefined;
	const selected = selectedParam?.split(SEPARATOR).filter(Boolean) ?? [];
	const rowSelection: RowSelectionState = selected.reduce(
		(acc, key) => Object.assign(acc, { [key]: true }),
		{},
	);

	function onRowSelectionChange(updaterOrValue: Updater<RowSelectionState>) {
		const params =
			typeof updaterOrValue === "function"
				? updaterOrValue(rowSelection ?? {})
				: updaterOrValue;

		navigate({
			search: (prev) => ({
				...prev,
				selected: Object.keys(params).join(SEPARATOR),
			}),
		});
	}

	const rowSelectionProps = {
		enableMultiRowSelection: true,
		state: { rowSelection: rowSelection },
		onRowSelectionChange: onRowSelectionChange,
	};

	const tableProps = {
		...paginationProps,
		...rowSelectionProps,
		state: { ...paginationProps.state, ...rowSelectionProps.state },
	};

	return {
		pagination,
		onPaginationChange,
		paginationProps,

		rowSelection,
		onRowSelectionChange,
		rowSelectionProps,
		selected,

		tableProps,
	};
}
