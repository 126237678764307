import { faMoonStars, faSunBright } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Icon } from "~/components/utilities/IconVariant";
import { Square } from "~/components/utilities/interactive/Square";

export function DarkLightToggle() {
	const systemMotion = useMediaQuery("(prefers-reduced-motion: reduce)");
	const systemDark = useMediaQuery("(prefers-color-scheme: dark)");
	const [state, setState] = useState<"dark" | "light">(
		systemDark ? "dark" : "light",
	);

	useEffect(() => {
		const isDark =
			document.documentElement.getAttribute("data-theme") === "dark";

		setState(isDark ? "dark" : "light");
	}, []);

	return (
		<Square size="large" color="background-gray">
			<button
				title="Thema wisselen"
				type="button"
				onClick={async () => {
					document.documentElement.setAttribute("data-motion", "reduce");

					setState((prev) => (prev === "light" ? "dark" : "light"));

					document.documentElement.setAttribute(
						"data-theme",
						state === "light" ? "dark" : "light",
					);

					localStorage.setItem("theme", state === "light" ? "dark" : "light");

					setTimeout(() => {
						document.documentElement.setAttribute(
							"data-motion",
							systemMotion ? "reduce" : "no-preference",
						);
					}, 150); // this should match the maximum transition duration used in project
				}}
			>
				<Icon icon={state === "light" ? faSunBright : faMoonStars} />
			</button>
		</Square>
	);
}
