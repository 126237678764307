import type { ReactNode } from "react";
import { CommandProvider } from "~/components/command/provider";
import { Navigation } from "~/components/utilities/layout/navigation/Navigation";
import { MobileNavigation } from "~/components/utilities/layout/navigation/MobileNavigation";

export function LayoutComponent({ children }: { children: ReactNode }) {
	return (
		<CommandProvider>
			<div className="mx-auto flex xs:grid min-h-screen max-w-[calc(1792px+16rem)] xs:grid-cols-[auto,_minmax(0,_1fr)] flex-col bg-gradient-to-tl from-70% from-gray-2 to-primary-2">
				<Navigation />

				<div className="flex grow flex-col gap-4 [--header-y:46px] [--subheader-y:40px] xs:[--header-y:42px]">
					<header className="sticky top-0 z-2 pt-4">
						<div className="mx-4 flex items-center gap-4 rounded-lg border border-gray-3 bg-background px-4 py-2 shadow-sm">
							<MobileNavigation />
							<div className="flex h-[26px] items-center">Search</div>
						</div>
					</header>

					<div className="scrollbar-none flex max-h-[calc(100vh-var(--header-y)-2rem)] grow snap-y flex-col gap-4 overflow-y-auto *:snap-start">
						{children}
					</div>
				</div>
			</div>
		</CommandProvider>
	);
}
