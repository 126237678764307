import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FocusScope } from "@radix-ui/react-focus-scope";
import { Portal } from "@radix-ui/react-portal";
import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	useState,
} from "react";
import { RemoveScroll } from "react-remove-scroll";
import { Icon } from "~/components/utilities/IconVariant";
import { Typography } from "~/components/utilities/Typography";
import { Square } from "~/components/utilities/interactive/Square";
import { useEscapeKeydown } from "~/hooks/use-escape-keydown";
import { cn } from "~/hooks/use-tailwind";

type DialogControlProps =
	| {
			open?: never;
			setOpen?: never;
	  }
	| {
			open: boolean;
			setOpen: Dispatch<SetStateAction<boolean>>;
	  };

interface DefaultDialogProps {
	title?: string;
	children: ReactNode;
	className?: string;
	forceOpen?: boolean;
	width?: "xs" | "sm";
}

export type DialogProps = DialogControlProps & DefaultDialogProps;

export function Dialog(props: DialogProps) {
	const { title, children, className, forceOpen, width = "sm" } = props;
	const [openState, setOpenState] = useState(false);

	const open = props.open ?? openState;
	const setOpen = forceOpen ? undefined : (props.setOpen ?? setOpenState);
	useEscapeKeydown(() => setOpen?.(false));

	return (
		<Portal>
			<div
				onClick={() => setOpen?.(false)}
				onKeyDown={(event) => event.key === "Escape" && setOpen?.(false)}
				className={cn(
					"fixed inset-0 z-3 bg-background/50 backdrop-blur-[2px] safe-motion:transition",
					{
						"pointer-events-none opacity-0": !open,
						"pointer-events-auto opacity-100": !!open,
					},
				)}
			/>

			<div
				onKeyDown={(event) => event.key === "Escape" && setOpen?.(false)}
				className={cn(
					"pointer-events-none fixed bottom-1/2 z-3 flex w-full translate-y-1/2 items-center justify-center safe-motion:transition",
					{
						"scale-90 opacity-0": !open,
						"scale-100 opacity-100": open,
					},
				)}
			>
				<FocusScope loop={open} trapped={open} asChild>
					<RemoveScroll
						enabled={open}
						className={cn(
							"mx-2 w-full rounded bg-background p-4 shadow-md focus:outline-none md:mx-6",
							{
								"pointer-events-auto": open,
								"sm:max-w-screen-sm": width === "sm",
								"xs:max-w-screen-xs": width === "xs",
							},
						)}
					>
						{(title || !forceOpen) && (
							<div className="mb-2 flex min-h-8 items-start justify-between">
								{title && (
									<Typography weight="bold" size="lg">
										{title}
									</Typography>
								)}

								{!forceOpen && (
									<Square size="small">
										<button type="button" onClick={() => setOpen?.(false)}>
											<Icon icon={faTimes} size="xl" />
										</button>
									</Square>
								)}
							</div>
						)}

						<div className={className}>{children}</div>
					</RemoveScroll>
				</FocusScope>
			</div>
		</Portal>
	);
}
