import type { Middleware } from "openapi-fetch";

interface ErrorResponse {
	errors: Record<string, string[]>;
	message: string;
}
export class ValidationError extends Error {
	public readonly errors: ErrorResponse["errors"];
	constructor(message: string, errors: ErrorResponse["errors"]) {
		super(message);

		this.name = "ValidationError";
		this.errors = errors;
	}
}

export class HTTPError extends Error {
	public readonly statusCode: number;
	constructor(message: string, statusCode: number) {
		super(message);

		this.name = "HTTPError";
		this.statusCode = statusCode;
	}
}

export const errorMiddleware: Middleware = {
	onResponse: async ({ response }) => {
		const isJson = response.headers
			.get("content-type")
			?.includes("application/json");

		if (response.status === 422 && isJson) {
			const json = (await response.json()) as ErrorResponse;

			throw new ValidationError(json.message, json.errors);
		}

		if (!response.ok) {
			if (isJson) {
				const json = await response.json();

				throw new HTTPError(json.message, response.status);
			}

			throw new HTTPError(await response.text(), response.status);
		}
	},
};
