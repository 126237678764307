import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { $api } from "~/api";

export const userQueryOptions = $api.queryOptions("get", "/api/v1/me");

export const useUser = () => useSuspenseQuery(userQueryOptions);

export const useLogout = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return $api.useMutation("post", "/api/v1/logout", {
		onSuccess: async () => {
			await queryClient.invalidateQueries();

			await navigate({ to: "/login", search: { redirect: "/" } });
		},
	});
};
