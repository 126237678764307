import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import type Common from "~/lang/en/common.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

export const DEFAULT_LOCALE = "en-NL";
export const defaultNS = "common";

declare module "i18next" {
	interface CustomTypeOptions {
		defaultNS: typeof defaultNS;
		resources: {
			common: typeof Common;
		};
	}
}

export default i18next
	.use({
		type: "backend",
		read: async (
			language: string,
			namespace: string,
			callback: (a: null, b: object) => void,
		) => {
			try {
				const file = await import(`./lang/${language}/${namespace}.json`);

				callback(null, file.default);
			} catch (_error) {
				callback(null, {});
			}
		},
	})
	.use(initReactI18next)
	.init({
		defaultNS: "common",
		lng: DEFAULT_LOCALE,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});
