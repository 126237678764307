import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { type VariantProps, tv } from "tailwind-variants";
import type { IconProps } from "~/components/utilities/IconVariant";

export const interactiveSquare = tv({
	base: "theme-focus-visible aspect-square shrink-0 leading-none safe-motion:transition-colors",
	variants: {
		color: {
			background: "bg-background text-gray-11 hover:bg-gray-2",
			"background-gray": "bg-gray-2 text-gray-11 hover:bg-gray-3",
			gray: "bg-gray-3 text-gray-11 hover:bg-gray-4",
		},
		rounded: {
			full: "rounded-full",
			default: "rounded",
		},
		size: {
			small: "w-[20px] text-sm",
			medium: "w-[30px] text-base",
			large: "w-[42px] text-lg",
		},
	},
	defaultVariants: {
		color: "background",
		rounded: "default",
		size: "medium",
	},
});

export const interactiveAnchor = tv({
	base: "cursor-pointer appearance-none text-left hover:underline focus:outline-none focus-visible:underline",
	variants: {
		color: {
			primary: "text-primary-9",
			gray: "text-gray-9",
			dark: "text-gray-12",
		},
	},
	defaultVariants: {
		color: "primary",
	},
});

export type AnchorVariantProps = VariantProps<typeof interactiveAnchor>;

export const interactiveAction = tv({
	base: "appearance-none rounded outline-none safe-motion:transition-colors",
	variants: {
		color: {
			white:
				"border border-gray-3 bg-gray-1 text-gray-12 hover:bg-gray-3 focus-visible:border-gray-7 focus-visible:bg-gray-3",
			gray: "border-gray-3 bg-gray-2 text-gray-12 hover:bg-gray-3 focus-visible:border-gray-7 focus-visible:bg-gray-3",
		},
		size: {
			small: "px-1.5 py-0.5 text-sm",
			medium: "px-3 py-1",
			large: "px-4.5 py-1.5 text-lg",
		},
		style: {
			solid: "border-solid",
			dashed: "border-dashed",
		},
	},
	defaultVariants: {
		color: "gray",
		size: "medium",
		style: "solid",
	},
});

export type ActionVariantProps = VariantProps<typeof interactiveAction>;

export const interactiveButton = tv({
	base: "appearance-none rounded border-2 outline-none safe-motion:transition-colors focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-70",
	variants: {
		variant: {
			solid: "text-white",
			outline: "",
		},
		size: {
			small: "px-1.5 py-0.5 text-sm",
			medium: "px-3 py-1",
			large: "px-4.5 py-1.5 text-lg",
		},
		color: {
			primary: "focus-visible:ring-primary-9",
			gray: "focus-visible:ring-gray-9",
			green: "focus-visible:ring-green-9",
			yellow: "focus-visible:ring-yellow-9",
			red: "focus-visible:ring-red-9",
		},
	},
	defaultVariants: {
		variant: "solid",
		size: "medium",
		color: "primary",
	},
	compoundVariants: [
		{
			variant: "outline",
			color: "primary",
			className:
				"border-primary-9 bg-primary-3 text-primary-11 hover:enabled:bg-primary-4",
		},
		{
			variant: "solid",
			color: "primary",
			className:
				"border-primary-9 bg-primary-9 hover:enabled:border-primary-10 hover:enabled:bg-primary-10 focus-visible:hover:enabled:ring-primary-10",
		},
		{
			variant: "outline",
			color: "gray",
			className: "border-gray-9 bg-gray-3 text-gray-11 hover:enabled:bg-gray-4",
		},
		{
			variant: "solid",
			color: "gray",
			className:
				"border-gray-9 bg-gray-9 hover:enabled:border-gray-10 hover:enabled:bg-gray-10 focus-visible:hover:enabled:ring-gray-10",
		},
		{
			variant: "outline",
			color: "green",
			className:
				"border-green-9 bg-green-3 text-green-11 hover:enabled:bg-green-4",
		},
		{
			variant: "solid",
			color: "green",
			className:
				"border-green-9 bg-green-9 hover:enabled:border-green-10 hover:enabled:bg-green-10 focus-visible:hover:enabled:ring-green-10",
		},
		{
			variant: "outline",
			color: "yellow",
			className:
				"border-yellow-9 bg-yellow-3 text-yellow-11 hover:enabled:bg-yellow-4",
		},
		{
			variant: "solid",
			color: "yellow",
			className:
				"border-yellow-9 bg-yellow-9 hover:enabled:border-yellow-10 hover:enabled:bg-yellow-10 focus-visible:hover:enabled:ring-yellow-10",
		},
		{
			variant: "outline",
			color: "red",
			className: "border-red-9 bg-red-3 text-red-11 hover:enabled:bg-red-4",
		},
		{
			variant: "solid",
			color: "red",
			className:
				"border-red-9 bg-red-9 hover:enabled:border-red-10 hover:enabled:bg-red-10 focus-visible:hover:enabled:ring-red-10",
		},
	],
});

export type ButtonVariantProps = VariantProps<typeof interactiveButton>;

export type TypeProps =
	| {
			variant?: "link";
			color?: AnchorVariantProps["color"];
			style?: never;
			size?: never;
	  }
	| {
			variant?: "action";
			color?: ActionVariantProps["color"];
			style?: ActionVariantProps["style"];
			size?: never;
	  }
	| {
			variant?: ButtonVariantProps["variant"];
			color?: ButtonVariantProps["color"];
			style?: never;
			size?: ButtonVariantProps["size"];
	  };

export type InteractiveIconProps = {
	startIcon?: IconProp;
	endIcon?: IconProp;
	iconSize?: IconProps["size"];
	iconColor?: IconProps["color"];
};
