import type { ReactNode } from "react";
import { Typography } from "~/components/utilities/Typography";
import { cn } from "~/hooks/use-tailwind";

interface Props {
	title: string;
	children: ReactNode;
	className?: string;
}

export function PageLayout({ title, children, className }: Props) {
	return (
		<div className="mx-4 mb-4 grow rounded-lg border border-gray-3 bg-background shadow-sm">
			<div className="border-gray-3 border-b px-4 py-2">
				<Typography
					weight="bold"
					size="lg"
					component="h1"
					className="shrink-0 leading-[25px]"
				>
					{title}
				</Typography>
			</div>

			<div className={cn("p-4", className)}>{children}</div>
		</div>
	);
}
