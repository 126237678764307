import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Link, type LinkProps } from "@tanstack/react-router";
import { Fragment, type ReactNode } from "react";
import { Icon } from "~/components/utilities/IconVariant";
import { cn } from "~/hooks/use-tailwind";
import { combineAndRemoveSubset } from "~/library/utilities";

type DropDownMenuItem =
	| {
			label: string;
			href: Pick<LinkProps, "to" | "search">;
			subitems?: never;
	  }
	| {
			label: string;
			href?: never;
			subitems: DropDownMenuItemProps[];
	  };

interface DropDownMenuProps extends DropdownMenu.DropdownMenuProps {
	children: ReactNode;
	content?: ReactNode | null;
	items?: DropDownMenuItem[] | null;
	contentProps?: DropdownMenu.DropdownMenuContentProps;
	triggerProps?: DropdownMenu.DropdownMenuTriggerProps;
}

export function DropDownMenu(props: DropDownMenuProps) {
	const { children, contentProps, triggerProps, content, items, ...rest } =
		props;
	const {
		side = "bottom",
		className: contentClassName,
		...restContentProps
	} = contentProps ?? {};

	return (
		<DropdownMenu.Root modal={false} {...rest}>
			<DropdownMenu.Trigger asChild {...triggerProps}>
				{children}
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<DropdownMenu.Content
					side={side}
					className={cn(
						"min-w-48 rounded-lg border border-gray-3 bg-gray-2 p-1 shadow-sm",
						contentClassName,
					)}
					{...restContentProps}
				>
					<DropdownMenu.Arrow
						className="fill-current text-gray-3"
						width="12"
						height="10"
					/>

					{content}

					{items?.map((item) => (
						<Fragment key={item.label}>
							{item.subitems ? (
								<DropdownMenu.Sub>
									<DropdownMenu.SubTrigger className="flex items-center justify-between gap-4 rounded bg-gray-2 px-1 py-1 hover:bg-gray-3">
										<span>{item.label}</span>
										<Icon icon={faAngleRight} color="gray" />
									</DropdownMenu.SubTrigger>

									<DropdownMenu.Portal>
										<DropdownMenu.SubContent
											sideOffset={5}
											className="min-w-48 rounded-lg border border-gray-3 bg-gray-2 p-1 shadow-sm"
										>
											{item.subitems.map((subitem) => (
												<DropDownMenuItem key={subitem.label} {...subitem} />
											))}
										</DropdownMenu.SubContent>
									</DropdownMenu.Portal>
								</DropdownMenu.Sub>
							) : (
								<DropDownMenuItem {...item} />
							)}
						</Fragment>
					))}
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
}

interface DropDownMenuItemProps {
	label: string;
	href: Pick<LinkProps, "to" | "search">;
}

function DropDownMenuItem({ label, href }: DropDownMenuItemProps) {
	return (
		<DropdownMenu.Item textValue={label} asChild>
			<Link
				to={href.to}
				search={(prev) =>
					typeof href.search === "object"
						? {
								...prev,
								page: 1,
								filter: combineAndRemoveSubset(
									prev.filter ?? {},
									href.search.filter ?? {},
								),
							}
						: href.search
				}
				className="flex rounded bg-gray-2 px-4 py-1 hover:bg-gray-4 focus:outline-none focus-visible:bg-gray-4"
				activeProps={{ className: "text-primary-11 bg-gray-3" }}
			>
				{label}
			</Link>
		</DropdownMenu.Item>
	);
}
